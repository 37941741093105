import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccessToken } from '../../../actions/callActions';
import { twimlInit } from '../../../actions/settingActions';
import { SET_USER_PREFERENCE } from '../../../actions/types';

const CallSettingsPopover = (props) => {
  const { toNumbers, popoverState, setShowPopover, makeCall } = props;

  const twilioNumbers = useSelector(({ userPreference }) =>
    userPreference?.twilio_module_numbers?.filter(
      (item) =>
        item.gateway_type === 'TWILIO' ||
        item.is_verified_twilio_caller_id === 1
    )
  );
  const outgoingTwilioNumber = useSelector(({ userPreference }) =>
    userPreference?.twilio_module_numbers?.find(
      (item) => item.user_configured_for_outgoing === 1
    )
  );
  const defaultAccountSid = useSelector(
    ({ userPreference }) => userPreference?.settings.accountSidToCall
  );

  const [outgoingTwilioModuleNumber, setOutgoingTwilioModuleNumber] = useState(
    null
  );
  const [toNumber, setToNumber] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setToNumber(toNumbers?.[0]?.to_number);
  }, [toNumbers]);

  useEffect(() => {
    setOutgoingTwilioModuleNumber(outgoingTwilioNumber);
  }, [outgoingTwilioNumber]);

  const saveSettings = () => {
    if (
      outgoingTwilioModuleNumber.twilio_number ===
      outgoingTwilioNumber.twilio_number
    ) {
      return;
    }

    const account_sid = outgoingTwilioModuleNumber?.separate_twilio_account_sid
      ? outgoingTwilioModuleNumber.separate_twilio_account_sid
      : defaultAccountSid;

    let selectedIncomingNumbers = [];
    let unselectedIncomingNumbers = [];
    twilioNumbers?.forEach((twilioModuleNumber) => {
      if (twilioModuleNumber.user_configured_for_incoming === 1) {
        selectedIncomingNumbers = [
          ...selectedIncomingNumbers,
          {
            phoneNumber: twilioModuleNumber.twilio_number,
            phoneNumberSID: twilioModuleNumber.phone_sid,
            voiceUrl: twilioModuleNumber.voice_url,
            accountSID: twilioModuleNumber.separate_twilio_account_sid
          }
        ];
      } else {
        unselectedIncomingNumbers = [
          ...unselectedIncomingNumbers,
          {
            phoneNumber: twilioModuleNumber.twilio_number,
            phoneNumberSID: twilioModuleNumber.phone_sid,
            voiceUrl: twilioModuleNumber.voice_url,
            accountSID: twilioModuleNumber.separate_twilio_account_sid
          }
        ];
      }
    });

    const outgoingNumber = {
      phoneNumber: outgoingTwilioModuleNumber?.twilio_number || '',
      phoneNumberSID: outgoingTwilioModuleNumber?.phone_sid || ''
    };
    const data = {
      accountSid: account_sid,
      incomingNumbers: selectedIncomingNumbers,
      nonSelectedNumbers: unselectedIncomingNumbers,
      outgoingNumber: outgoingNumber
    };

    twimlInit(data).then((res) => {
      fetchAccessToken().then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_USER_PREFERENCE,
            payload: response.data
          });
        }
      });
    });
  };

  return (
    <IonPopover
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() =>
        setShowPopover({ showPopover: false, event: undefined })
      }
    >
      <IonList>
        <IonItem lines="none">
          <IonLabel position="floating">Calling to</IonLabel>
          <IonSelect
            value={toNumber}
            placeholder="Select"
            onIonChange={(e) => setToNumber(e.detail.value)}
          >
            {toNumbers &&
              toNumbers.map((toNumberOption) => {
                return (
                  <IonSelectOption
                    value={toNumberOption.to_number}
                    key={toNumberOption.to_number}
                  >
                    {toNumberOption.to_number}
                  </IonSelectOption>
                );
              })}
          </IonSelect>
        </IonItem>
        <IonItem lines="none" style={{ paddingTop: '20px' }}>
          <IonLabel position="floating">Calling from</IonLabel>
          <IonSelect
            value={outgoingTwilioModuleNumber}
            placeholder="Select"
            onIonChange={(e) => setOutgoingTwilioModuleNumber(e.detail.value)}
          >
            {twilioNumbers &&
              twilioNumbers.map((fromNumberOption) => {
                return (
                  <IonSelectOption
                    value={fromNumberOption}
                    key={fromNumberOption.twilio_number}
                  >
                    {fromNumberOption.twilio_number}
                  </IonSelectOption>
                );
              })}
          </IonSelect>
        </IonItem>
        <IonItem
          lines="none"
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}
        >
          <IonButton
            style={{ width: '100%', height: '100%' }}
            onClick={() => {
              saveSettings();
              makeCall(outgoingTwilioModuleNumber.twilio_number, toNumber);
            }}
          >
            Call
          </IonButton>
        </IonItem>
      </IonList>
    </IonPopover>
  );
};

export default CallSettingsPopover;
