import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: "https://5be1e6ef7a934bc49d8647b07c4728e8@sentry.delugeonaluge.com/8",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

serviceWorker.register();
