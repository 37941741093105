import { useCallback, useEffect, useRef } from 'react';
import './ChatViewer.css';
import {
  CellMeasurerCache,
  CellMeasurer,
  List,
  AutoSizer
} from 'react-virtualized';
import Message from './Message';
import { IonSkeletonText } from '@ionic/react';
import VoiceMessage from './VoiceMessage';
import { Media, MEDIA_STATUS } from '@awesome-cordova-plugins/media';

const ChatViewer = ({ messages, isLoading }) => {
  const cache = new CellMeasurerCache({
    defaultHeight: 150,
    minHeight: 75
  });

  const listRef = useRef(null);
  const mediaObject = useRef(null);
  const currentAudioUrl = useRef(null);
  const mediaStatus = useRef(MEDIA_STATUS.NONE);
  const currentMediaPosition = useRef(0);
  const mediaPlayerInterval = useRef(null);

  useEffect(() => {
    mediaPlayerInterval.current = setInterval(() => {
      if (!mediaObject.current) {
        return;
      }

      switch (mediaStatus.current) {
        case MEDIA_STATUS.RUNNING:
        case MEDIA_STATUS.PAUSED:
          mediaObject.current.getCurrentPosition().then((position) => {
            currentMediaPosition.current = position;
            if (listRef.current != null) {
              listRef.current.forceUpdateGrid();
            }
          });
          break;
        default:
          currentMediaPosition.current = 0;
          if (listRef.current != null) {
            listRef.current.forceUpdateGrid();
          }
          break;
      }
    }, 100);

    return () => {
      mediaObject.current?.release();
      if (mediaPlayerInterval.current) {
        clearInterval(mediaPlayerInterval.current);
      }
    };
  }, []);

  const playAudioButtonClick = (audioUrl) => {
    if (audioUrl === currentAudioUrl.current) {
      if (mediaStatus.current === MEDIA_STATUS.STARTING) {
        return;
      } else if (mediaStatus.current === MEDIA_STATUS.RUNNING) {
        mediaObject.current?.pause();
        return;
      } else if (mediaStatus.current === MEDIA_STATUS.PAUSED) {
        mediaObject.current?.play();
        return;
      }
    }

    currentAudioUrl.current = audioUrl;
    if (listRef.current != null) {
      listRef.current.forceUpdateGrid();
    }

    mediaObject.current?.release();
    mediaObject.current = Media.create(audioUrl);
    mediaObject.current.play();
    mediaObject.current.onStatusUpdate.subscribe((status) => {
      // play until playable
      if (
        currentMediaPosition.current === 0 &&
        status === MEDIA_STATUS.RUNNING
      ) {
        mediaObject.current?.play();
      }

      mediaStatus.current = status;
      if (listRef.current != null) {
        listRef.current.forceUpdateGrid();
      }
    });
  };

  const getAudioUrlFromMessage = (msg) => {
    return msg.voice_mail_recording_url
      ? msg.voice_mail_recording_url
      : msg.call_recording_url;
  };

  const chatRowRenderer = ({ index, style, key, parent }) => {
    const msg = messages[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
          {getAudioUrlFromMessage(msg)?.length > 0 ? (
            <VoiceMessage
              key={index}
              contents={msg}
              networkDuration={mediaObject.current?.getDuration()}
              mediaStatus={
                getAudioUrlFromMessage(msg) === currentAudioUrl.current
                  ? mediaStatus.current
                  : MEDIA_STATUS.NONE
              }
              currentPosition={
                getAudioUrlFromMessage(msg) === currentAudioUrl.current
                  ? currentMediaPosition.current
                  : 0
              }
              playAudioButtonClick={playAudioButtonClick}
            />
          ) : (
            <Message key={index} contents={msg} />
          )}
        </div>
      </CellMeasurer>
    );
  };

  const ChatList = useCallback(
    ({ width, rowRenderer, rowHeight, height }) => (
      <List
        ref={(ref) => (listRef.current = ref)}
        rowCount={messages.length}
        scrollToIndex={messages.length}
        width={width}
        height={height}
        rowHeight={rowHeight}
        rowRenderer={rowRenderer}
      />
    ),
    [messages]
  );

  return (
    <div className="chat__viewer">
      {isLoading && (
        <div className="ion-padding">
          <IonSkeletonText animated className="message__skeleton" />
          <IonSkeletonText
            animated
            className="message__skeleton message__skeleton__sender"
          />
          <IonSkeletonText animated className="message__skeleton" />
          <IonSkeletonText
            animated
            className="message__skeleton message__skeleton__sender"
          />
          <IonSkeletonText
            animated
            className="message__skeleton message__skeleton__sender"
          />
        </div>
      )}
      <AutoSizer>
        {({ width, height }) => (
          <ChatList
            rowRenderer={chatRowRenderer}
            width={width}
            height={height}
            rowHeight={cache.rowHeight}
            overscanRowCount={3}
            scrollToAlignment="end"
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ChatViewer;
