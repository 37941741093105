function isTrue(value) {
  if (value == null || value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
  }

  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}

export default isTrue;
