import { toastController } from '@ionic/core';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { fetchTaskOwners, saveCallLogs } from '../actions/callActions';

const outcomes = [
  'Busy',
  'Voicemail',
  'Not interested',
  'Call back later',
  'Good call - interested'
];

const CallLog = () => {
  let { callDuration, conferenceFriendlyName, contactName } = useParams();

  const [taskSubject, setTaskSubject] = useState('');
  const [callNote, setCallNote] = useState('');
  const [taskOwner, setTaskOwner] = useState('');
  const [noteStatus, setNoteStatus] = useState('');
  const [isNeedToCreateTask, setNeedToCreateTask] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  let history = useHistory();
  let taskOwners = useSelector(({ call }) => call.taskOwners);

  useEffect(() => {
    dispatch(fetchTaskOwners());
  }, [dispatch]);

  useEffect(() => {
    if (taskOwners.length > 0) {
      setTaskOwner(taskOwners[0].id);
    }
  }, [taskOwners]);

  async function logCall() {
    let toast = await toastController.create();
    toast.duration = 3000;
    if (!noteStatus) {
      toast.message = 'Please select an outcome';
      toast.present();
    } else if (!callNote) {
      toast.message = 'Please enter notes';
      toast.present();
    } else if (isNeedToCreateTask && !taskSubject) {
      toast.message = 'Please select an task subject';
      toast.present();
    } else {
      setIsSaving(true);
      let body = {
        conferenceFriendlyName,
        callNote,
        noteStatus,
        callDuration: parseInt(callDuration),
        taskOwner
      };
      saveCallLogs(body)
        .then((response) => {
          setIsSaving(false);
          history.goBack();
        })
        .catch((err) => {
          setIsSaving(false);
          alert(err);
        });
    }
  }

  const durationText = (durationInSeconds) => {
    const intDurationInSeconds = parseInt(durationInSeconds);
    const minutes = parseInt(intDurationInSeconds / 60);
    const seconds = intDurationInSeconds % 60;

    const minutesText = `${minutes} ` + (minutes > 1 ? 'minutes' : 'minute');
    const secondsText = `${seconds} ` + (seconds > 1 ? 'seconds' : 'second');

    if (minutes > 0) {
      if (seconds > 0) {
        return `${minutesText} ${secondsText}`;
      } else {
        return minutesText;
      }
    } else {
      return secondsText;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Call Log</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonItem class="ion-margin-top" lines="none">
          <IonLabel>Call with {contactName} completed</IonLabel>
        </IonItem>

        <IonItem class="ion-margin-top" lines="none">
          <IonLabel>Duration: {durationText(callDuration)}</IonLabel>
        </IonItem>

        <IonItem>
          <IonLabel>Outcome</IonLabel>
          <IonSelect
            interface="popover"
            onIonChange={(e) => setNoteStatus(e.detail.value)}
            placeholder=""
          >
            {outcomes.map((item) => {
              return <IonSelectOption value={item}>{item}</IonSelectOption>;
            })}
          </IonSelect>
        </IonItem>

        <IonItem class="ion-margin-top">
          <IonLabel position="stacked">Call Notes</IonLabel>
          <IonTextarea
            value={callNote}
            type="text"
            onIonChange={(e) => {
              setCallNote(e.target.value);
            }}
          />
        </IonItem>
        <IonItem>
          <IonCheckbox
            checked={isNeedToCreateTask}
            name="CreateTask"
            onIonChange={(e) => {
              setNeedToCreateTask(e.detail.checked);
            }}
            color="primary"
            slot="end"
          ></IonCheckbox>
          <IonLabel>Create Task</IonLabel>
        </IonItem>
        {isNeedToCreateTask && (
          <IonCol>
            <IonItem>
              <IonLabel>Task Owner</IonLabel>
              <IonSelect
                interface="popover"
                value={taskOwner}
                onIonChange={(e) => setTaskOwner(e.detail.value)}
                placeholder=""
              >
                {taskOwners.map((item) => {
                  return (
                    <IonSelectOption value={item.id}>
                      {item.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>

            <IonItem class="ion-margin-top">
              <IonLabel position="stacked">Task Subject</IonLabel>
              <IonInput
                value={taskSubject}
                onIonChange={(e) => {
                  setTaskSubject(e.target.value);
                }}
              />
            </IonItem>
          </IonCol>
        )}
        {
          <IonLoading
            isOpen={isSaving}
            onDidDismiss={() => setIsSaving(false)}
            message={'Please wait...'}
          />
        }
      </IonContent>
      <IonButton onClick={logCall} class="ion-margin" expand="block">
        Log Call
      </IonButton>
    </IonPage>
  );
};

export default CallLog;
