import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonPopover
} from '@ionic/react';
import { useHistory } from 'react-router';

const ChatRepliesPopover = (props) => {
  const {
    quickRepliesList,
    popoverState,
    setShowPopover,
    setActionsPopover,
    setInput
  } = props;
  let history = useHistory();

  const setTemplateOnInput = (template) => {
    setShowPopover({ showQuickRepliesPopover: false, event: undefined });
    setActionsPopover({ showPopover: false, event: undefined });
    setInput(template);
  };

  return (
    <IonPopover
      event={popoverState.event}
      isOpen={popoverState.showQuickRepliesPopover}
      onDidDismiss={() =>
        setShowPopover({ showQuickRepliesPopover: false, event: undefined })
      }
    >
      <IonList>
        {quickRepliesList &&
          quickRepliesList.map((item) => {
            return (
              <IonItem key={item.id}>
                <IonLabel
                  onClick={() => setTemplateOnInput(item.template_text)}
                  position=""
                >
                  {item.template_name}
                </IonLabel>
                <IonButton
                  onClick={() => {
                    history.push(
                      `/editreply?id=${item.template_id}&name=${item.template_name}&text=${item.template_text}`
                    );
                  }}
                >
                  Edit
                </IonButton>
              </IonItem>
            );
          })}
      </IonList>
      <IonButton
        onClick={() => {
          history.push('/addreply');
        }}
        expand="block"
      >
        + Add a quick reply
      </IonButton>
    </IonPopover>
  );
};

export default ChatRepliesPopover;
