import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { logOutIfRequestUnauthenticated } from '../actions/authActions';
import { fetchAccessToken } from '../actions/callActions';
import { useDispatch } from 'react-redux';
import ItemRowSkeleton from '../components/ItemRowSkeletons';
import { checkmark } from 'ionicons/icons';
import { twimlInit } from '../actions/settingActions';
import { useHistory } from 'react-router';
import { SET_USER_PREFERENCE } from '../actions/types';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [outgoingTwilioModuleNumber, setOutgoingTwilioModuleNumber] = useState(
    undefined
  );
  const dndOn = useRef();
  const twilioModuleNumbers = useRef([]);
  const inboundTwilioModuleNumbers = useRef([]);
  const selectedInboundTwilioModuleNumbers = useRef([]);
  const defaultAccountSid = useRef();
  let history = useHistory();

  useEffect(() => {
    fetchAccessToken()
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_USER_PREFERENCE,
            payload: response.data
          });

          if (response.data.twilio_module_numbers) {
            twilioModuleNumbers.current = response.data.twilio_module_numbers.filter(
              (item) =>
                item.gateway_type === 'TWILIO' ||
                item.is_verified_twilio_caller_id === 1
            );

            const outboundNumber = response.data.twilio_module_numbers.find(
              (item) => item.user_configured_for_outgoing === 1
            );
            setOutgoingTwilioModuleNumber(outboundNumber);

            inboundTwilioModuleNumbers.current = response.data.twilio_module_numbers.filter(
              (item) =>
                item.is_verified_twilio_caller_id !== 1 &&
                item.gateway_type === 'TWILIO'
            );
            selectedInboundTwilioModuleNumbers.current = response.data.twilio_module_numbers.filter(
              (item) => item.user_configured_for_incoming === 1
            );
          }
          if (response.data.settings.accountSidToCall) {
            defaultAccountSid.current = response.data.settings.accountSidToCall;
          }

          dndOn.current = response.data.availability_status === 'OFFLINE';
        }

        setIsLoading(false);
      })
      .catch((err) => {
        logOutIfRequestUnauthenticated(err, dispatch);
      });
  }, [dispatch]);

  const isSelectedInboundNumber = (twilioModuleNumber) =>
    !!selectedInboundTwilioModuleNumbers.current.find(
      (item) => item.friendly_name === twilioModuleNumber.friendly_name
    );

  const saveSettings = () => {
    const account_sid = outgoingTwilioModuleNumber?.separate_twilio_account_sid
      ? outgoingTwilioModuleNumber.separate_twilio_account_sid
      : defaultAccountSid.current;

    let selectedIncomingNumbers = [];
    let unselectedIncomingNumbers = [];
    twilioModuleNumbers.current.forEach((twilioModuleNumber) => {
      if (isSelectedInboundNumber(twilioModuleNumber)) {
        selectedIncomingNumbers = [
          ...selectedIncomingNumbers,
          {
            phoneNumber: twilioModuleNumber.twilio_number,
            phoneNumberSID: twilioModuleNumber.phone_sid,
            voiceUrl: twilioModuleNumber.voice_url,
            accountSID: twilioModuleNumber.separate_twilio_account_sid
          }
        ];
      } else {
        unselectedIncomingNumbers = [
          ...unselectedIncomingNumbers,
          {
            phoneNumber: twilioModuleNumber.twilio_number,
            phoneNumberSID: twilioModuleNumber.phone_sid,
            voiceUrl: twilioModuleNumber.voice_url,
            accountSID: twilioModuleNumber.separate_twilio_account_sid
          }
        ];
      }
    });

    const outgoingNumber = {
      phoneNumber: outgoingTwilioModuleNumber?.twilio_number || '',
      phoneNumberSID: outgoingTwilioModuleNumber?.phone_sid || ''
    };

    const data = {
      accountSid: account_sid,
      incomingNumbers: selectedIncomingNumbers,
      nonSelectedNumbers: unselectedIncomingNumbers,
      outgoingNumber: outgoingNumber,
      status: dndOn.current ? 'OFFLINE' : 'AVAILABLE'
    };

    setIsSaving(true);
    twimlInit(data)
      .then(() => {
        fetchAccessToken().then((response) => {
          if (response.status === 200) {
            dispatch({
              type: SET_USER_PREFERENCE,
              payload: response.data
            });
          }
        });

        setIsSaving(false);
        history.goBack();
      })
      .catch((err) => {
        setIsSaving(false);
        alert(err);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          {!isLoading && (
            <IonButtons slot="end">
              <IonButton fill="clear" size="small" onClick={saveSettings}>
                <IonIcon size="large" icon={checkmark} />
              </IonButton>
            </IonButtons>
          )}
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {isLoading ? (
          <ItemRowSkeleton quantity={9} height={20} />
        ) : (
          <IonList>
            <IonItem style={{ paddingTop: '20px' }}>
              <IonLabel position="floating">Use for outbound calls</IonLabel>
              <IonSelect
                value={outgoingTwilioModuleNumber}
                placeholder="Select"
                onIonChange={(e) => {
                  const outboundNumber = e.detail.value;
                  if (
                    outboundNumber?.separate_twilio_account_sid &&
                    selectedInboundTwilioModuleNumbers.current.length > 0
                  ) {
                    selectedInboundTwilioModuleNumbers.current = [
                      selectedInboundTwilioModuleNumbers.current[0]
                    ];
                  }
                  setOutgoingTwilioModuleNumber(outboundNumber);
                }}
              >
                {twilioModuleNumbers.current.map((twilioModuleNumber) => {
                  return (
                    <IonSelectOption
                      value={twilioModuleNumber}
                      key={twilioModuleNumber.friendly_name}
                    >
                      {twilioModuleNumber.friendly_name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>
            <IonListHeader style={{ paddingTop: '20px' }}>
              <IonLabel>Receive inbound calls</IonLabel>
            </IonListHeader>
            {outgoingTwilioModuleNumber?.separate_twilio_account_sid ? (
              <IonRadioGroup
                value={selectedInboundTwilioModuleNumbers.current[0]}
                onIonChange={(e) =>
                  (selectedInboundTwilioModuleNumbers.current = [
                    e.detail.value
                  ])
                }
              >
                {inboundTwilioModuleNumbers.current.map(
                  (twilioModuleNumber) => {
                    return (
                      <IonItem>
                        <IonLabel>{twilioModuleNumber.friendly_name}</IonLabel>
                        <IonRadio value={twilioModuleNumber} />
                      </IonItem>
                    );
                  }
                )}
              </IonRadioGroup>
            ) : (
              <>
                {inboundTwilioModuleNumbers.current.map(
                  (twilioModuleNumber) => {
                    return (
                      <IonItem>
                        <IonLabel>{twilioModuleNumber.friendly_name}</IonLabel>
                        <IonCheckbox
                          value={twilioModuleNumber}
                          checked={isSelectedInboundNumber(twilioModuleNumber)}
                          onIonChange={(e) => {
                            if (e.detail.checked) {
                              selectedInboundTwilioModuleNumbers.current = [
                                ...selectedInboundTwilioModuleNumbers.current,
                                e.detail.value
                              ];
                            } else {
                              selectedInboundTwilioModuleNumbers.current = selectedInboundTwilioModuleNumbers.current.filter(
                                (item) =>
                                  item.friendly_name !==
                                  e.detail.value.friendly_name
                              );
                            }
                          }}
                        />
                      </IonItem>
                    );
                  }
                )}
              </>
            )}
            <IonItem style={{ paddingTop: '20px' }}>
              <IonLabel>Do not disturb</IonLabel>
              <IonToggle
                checked={dndOn.current}
                onIonChange={(e) => {
                  dndOn.current = e.detail.checked;
                }}
              />
            </IonItem>
          </IonList>
        )}
        {
          <IonLoading
            isOpen={isSaving}
            onDidDismiss={() => setIsSaving(false)}
            message={'Please wait...'}
          />
        }
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
