import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { createTemplate } from '../actions/templateActions';

const AddQuickReply = () => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  let history = useHistory();

  async function save() {
    const response = await createTemplate(name, message);
    if (response.status === 200) {
      history.goBack();
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Quick Replies</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem class="ion-margin-top">
          <IonLabel position="stacked">Quick Reply Name</IonLabel>
          <IonInput
            value={name}
            onIonChange={(e) => {
              setName(e.target.value);
            }}
          />
        </IonItem>

        <IonItem class="ion-margin-top">
          <IonLabel position="stacked">Message</IonLabel>
          <IonText
            class="ion-margin-top"
            style={{ fontSize: 10, marginTop: 10 }}
          >
            Press # to choose merge fields
          </IonText>
          <IonTextarea
            value={message}
            type="text"
            onIonChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </IonItem>

        <IonButton onClick={save} class="ion-margin" expand="block">
          Add
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default AddQuickReply;
