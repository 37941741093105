import { Capacitor, Plugins } from '@capacitor/core';
import { AppVersion } from '@ionic-native/app-version';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonRouter
} from '@ionic/react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import {
  arrowForward,
  arrowForwardSharp,
  filterOutline,
  peopleOutline
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { version } from '../../package.json';
import { logOutIfRequestUnauthenticated } from '../actions/authActions';
import { fetchRecentConversations } from '../actions/smsActions';
import ChatsHeader from '../components/Chats/ChatsHeader';
import ChatsRowItem from '../components/Chats/ChatsRowItem';
import ItemRowSkeleton from '../components/ItemRowSkeletons';
import PullToRefresh from '../components/PullToRefresh';
import {
  isStorageAvailable,
  safelyRetrieveLocalStorageItem,
  safelySetLocalStorageItem
} from '../components/SafelyParseJson';
import './Popover.css';

const { App } = Plugins;

const ChatsPage = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const searchRef = useRef(true);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  });
  const [conversationsFilter, setConversationsFilter] = useState({
    filterMode: null,
    userFilters: null,
    showLeads: true,
    showContacts: true,
    showDeals: true,
    showCustomModules: true,
    perPage: 20,
    pageNum: 1,
    newestFirst: true
  });

  const { data: dashboardData, refetch: refetchChats } = useQuery(
    ['conversationsQuery', conversationsFilter],
    conversationsFilter.filterMode && fetchRecentConversations
  );

  const ionRouter = useIonRouter();
  document.addEventListener('ionBackButton', (ev) => {
    ev.detail.register(-1, () => {
      if (!ionRouter.canGoBack()) {
        App.exitApp();
      }
    });
  });

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const searchWithDelay = debounce((e) => {
    const value = e.target.value;
    setSearch(value);

    if (value) {
      axios
        .get(`api/search/${value}`)
        .then((response) => {
          setMessages(response.data.searchResult);
          setRecords(response.data.recordsCount);
          if (response.data.recordsCount === 0) {
            setSearch(response.data);
          }
        })
        .catch((err) => {
          logOutIfRequestUnauthenticated(err, dispatch);
        });
    } else {
      setMessages(dashboardData?.data?.contacts ?? []);
      setRecords(dashboardData?.data?.contacts?.length ?? []);
    }
  });

  const handleSearchInputChange = (e) => {
    searchWithDelay(e);
  };

  const handleRefresh = async (e) => {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'Videos',
      eventAction: 'play',
      eventLabel: 'Fall Campaign'
    });

    return new Promise((resolve) => {
      refetchChats().then(() => {
        resolve();
      });
    });
  };

  const handleSearchButton = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  useEffect(() => {
    async function init() {
      let filterMode = 'unreplied';
      let userFilters = 'just-mine';

      if (isStorageAvailable()) {
        filterMode =
          (await safelyRetrieveLocalStorageItem('filterMode')).value ||
          'unreplied';
        userFilters =
          (await safelyRetrieveLocalStorageItem('userFilters')).value ||
          'just-mine';
      }

      setConversationsFilter({
        ...conversationsFilter,
        filterMode,
        userFilters
      });

      let versionCode;
      let platform;
      if (Capacitor.isNative) {
        let res = await AppVersion.getVersionCode();
        versionCode = res;
        platform = Capacitor.getPlatform();
      } else {
        versionCode = version;
        platform = 'web';
      }

      let currentUser = JSON.parse(window.localStorage.getItem('user'));

      Sentry.setUser({
        username: currentUser.name,
        id: currentUser.zoho_user_id
      });
      Sentry.setTag('appVersion', versionCode);
      Sentry.setTag('platform', platform);
      Sentry.setContext('app', { appVersion: versionCode });
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashboardData?.data?.contacts) {
      let filteredMessages = dashboardData.data.contacts.filter((message) => {
        if (
          (conversationsFilter.showContacts &&
            message.crm_module_name === 'Contacts') ||
          (conversationsFilter.showLeads &&
            message.crm_module_name === 'Leads') ||
          (conversationsFilter.showDeals &&
            message.crm_module_name === 'Deals') ||
          (conversationsFilter.showCustomModules &&
            message.crm_module_name !== 'Deals' &&
            message.crm_module_name !== 'Leads' &&
            message.crm_module_name !== 'Contacts')
        ) {
          return true;
        } else return false;
      });
      setMessages(filteredMessages);
      setRecords(filteredMessages.length);
    }
  }, [dashboardData, conversationsFilter]);

  useEffect(() => {
    if (dashboardData) {
      setIsLoading(false);
    }
  }, [dashboardData]);

  const returnModeName = () => {
    if (conversationsFilter.filterMode === 'unreplied')
      return <p style={{ display: 'inline' }}>Reply Needed</p>;
    else if (conversationsFilter.filterMode === 'most-recently-touched')
      return <p style={{ display: 'inline' }}>No Filter</p>;
    else if (conversationsFilter.filterMode === 'follow-up')
      return <p style={{ display: 'inline' }}>Follow Up</p>;
    else return '';
  };

  return (
    <IonPage>
      <ChatsHeader />
      <IonContent fullscreen>
        <PullToRefresh handleRefresh={handleRefresh} />
        {/* This shrinks the header on iphone */}
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Messages</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRow className="ion-align-items-center">
          <button
            ref={searchRef}
            style={{ position: 'absolute', backgroundColor: 'white' }}
          />
          <IonCol>
            <IonSearchbar
              onIonClear={() => {
                setTimeout(() => {
                  searchRef.current.focus();
                }, 100);
              }}
              onKeyUp={handleSearchButton}
              onIonChange={handleSearchInputChange}
            />
          </IonCol>

          <IonButton
            color="light"
            onClick={(e) => {
              setShowPopover({ showPopover: true, event: e });
            }}
            style={{
              '--border-radius': '999px',
              paddingRight: '10px'
            }}
          >
            <IonIcon icon={filterOutline} color="primary" slot="icon-only" />
          </IonButton>
        </IonRow>

        {records === 0 && search !== '' ? (
          <p style={{ color: 'black', textAlign: 'center' }}>
            No matching conversation found.
          </p>
        ) : null}
        {records === 0 && search === '' ? (
          <p style={{ color: 'black', textAlign: 'center' }}>
            There are no conversations for you to answer in {returnModeName()}{' '}
            mode. Try changing the filter setting{' '}
            <IonIcon icon={arrowForward} color="primary" slot="icon-only" />
            <IonIcon icon={filterOutline} color="primary" slot="icon-only" />
            <br /> If you want to start a new conversation, click on contact
            icon.{' '}
            <IonIcon
              icon={arrowForwardSharp}
              color="primary"
              slot="icon-only"
            />
            <IonIcon icon={peopleOutline} color="primary" slot="icon-only" />
          </p>
        ) : null}
        <IonPopover
          cssClass="contact-popover"
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() =>
            setShowPopover({ showPopover: false, event: undefined })
          }
        >
          <IonItem lines="none">
            <IonSegment
              value={conversationsFilter.filterMode}
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  filterMode: e.detail.value
                }));
                safelySetLocalStorageItem('filterMode', e.detail.value);
              }}
            >
              <IonSegmentButton value="most-recently-touched">
                <IonLabel>NO FILTER</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="unreplied">
                <IonLabel>REPLY NEEDED</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="follow-up">
                <IonLabel>FOLLOW UP</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonItem>
          <IonItem lines="none">
            <IonSegment
              value={conversationsFilter.userFilters}
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  userFilters: e.detail.value
                }));
                safelySetLocalStorageItem('userFilters', e.detail.value);
              }}
            >
              <IonSegmentButton value="all-messages">
                <IonLabel>ALL MESSAGES</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="just-mine">
                <IonLabel>JUST MINE</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="start">Show newest first</IonLabel>
            <IonToggle
              checked={conversationsFilter.newestFirst}
              name="newestFirst"
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  newestFirst: e.detail.checked
                }));
              }}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="start">Contacts</IonLabel>
            <IonCheckbox
              checked={conversationsFilter.showContacts}
              name="Contacts"
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  showContacts: e.detail.checked
                }));
              }}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="start">Leads</IonLabel>
            <IonCheckbox
              checked={conversationsFilter.showLeads}
              name="Leads"
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  showLeads: e.detail.checked
                }));
              }}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="start">Deals</IonLabel>
            <IonCheckbox
              checked={conversationsFilter.showDeals}
              name="Deals"
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  showDeals: e.detail.checked
                }));
              }}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="start">Custom Modules</IonLabel>
            <IonCheckbox
              checked={conversationsFilter.showCustomModules}
              name="CustomModules"
              onIonChange={(e) => {
                setConversationsFilter((prevState) => ({
                  ...prevState,
                  showCustomModules: e.detail.checked
                }));
              }}
            />
          </IonItem>
        </IonPopover>
        {isLoading ? (
          <ItemRowSkeleton quantity={9} height={20} />
        ) : (
          <IonList>
            {messages &&
              messages.map((message) => {
                if (
                  (conversationsFilter.showContacts &&
                    message.crm_module_name === 'Contacts') ||
                  (conversationsFilter.showLeads &&
                    message.crm_module_name === 'Leads') ||
                  (conversationsFilter.showDeals &&
                    message.crm_module_name === 'Deals') ||
                  (conversationsFilter.showCustomModules &&
                    message.crm_module_name !== 'Deals' &&
                    message.crm_module_name !== 'Leads' &&
                    message.crm_module_name !== 'Contacts')
                ) {
                  return <ChatsRowItem message={message} key={message.id} />;
                } else {
                  return <></>;
                }
              })}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ChatsPage;
