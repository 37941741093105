import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import setAuthToken from '../utils/setAuthToken';
import setBasePath from '../utils/setBasePath';

// Create a template
export const createTemplate = (name, text) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    crm_org_id: JSON.parse(localStorage.getItem('user')).zoho_org_id,
    template_text: text,
    template_name: name,
    template_id: uuidv4(),
    media_urls: null
  });
  // Request body
  return axios.post('/api/create-or-update-sms-templates', body, config);
};

// Edit a template
export const editTemplate = (id, name, text) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    crm_org_id: JSON.parse(localStorage.getItem('user')).zoho_org_id,
    template_text: text,
    template_name: name,
    template_id: id,
    media_urls: null
  });
  // Request body
  return axios.put('/api/create-or-update-sms-templates', body, config);
};

// Get all templates
export const getTemplates = () => {
  setBasePath();
  setAuthToken();
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  let orgId = JSON.parse(localStorage.getItem('user')).zoho_org_id;
  return axios.get(`/api/get-sms-templates?crm_org_id=${orgId}`, config);
};
