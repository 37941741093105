import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useSelector } from 'react-redux';
import { updateAvailabilityStatus } from '../actions/callActions';
import BackgroundFetch from 'cordova-plugin-background-fetch';

const HeartbeatWrapper = () => {
  let isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  useEffect(() => {
    if (Capacitor.isNative && isAuthenticated) {
      const TWENTY_FIVE_MINUTES_MS = 15 * 60 * 1000;

      initBackgroundFetch();

      updateAvailabilityStatus('UPDATE_LAST_ACTIVE');
      const interval = setInterval(() => {
        updateAvailabilityStatus('UPDATE_LAST_ACTIVE');
      }, TWENTY_FIVE_MINUTES_MS);

      return () => clearInterval(interval);
    }
  });

  const initBackgroundFetch = async () => {
    await BackgroundFetch.configure(
      {
        minimumFetchInterval: 15
      },
      async (taskId) => {
        console.log('[BackgroundFetch] EVENT:', taskId);
        await updateAvailabilityStatus('UPDATE_LAST_ACTIVE');
        console.log('[BackgroundFetch] work complete');
        BackgroundFetch.finish(taskId);
      },
      async (taskId) => {
        console.log('[BackgroundFetch] TIMEOUT:', taskId);
        BackgroundFetch.finish(taskId);
      }
    );
  };

  return <></>;
};
export default HeartbeatWrapper;
