import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import setBasePath from '../utils/setBasePath';
import { logOutIfRequestUnauthenticated } from './authActions';
import { IsDebug } from '@awesome-cordova-plugins/is-debug/ngx';
import { DISCONNECT_CALL, FETCH_TASK_OWNERS } from './types';

const isDebug = new IsDebug();

//Get acces token
export const fetchAccessToken = async () => {
  setBasePath();
  setAuthToken();

  // Headers
  let config = {
    timeout: 30000, // set timeout for 30 seconds
    params: {
      operating_system: Capacitor.platform
    },
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Added to test live certificate on dev server
  if (Capacitor.getPlatform() === 'ios') {
    const isDebugMode = await isDebug.getIsDebug();
    if (!isDebugMode) {
      config.params.environment = 'production';
    }
  }

  // Request body
  return axios.get('/api/access-token', config);
};

export const updateAvailabilityStatus = async (status) => {
  setBasePath();
  setAuthToken();

  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(
      'api/update-availability-status',
      JSON.stringify({ status }),
      config
    );
    if (res.status === 200) {
      console.log(
        'Received response for update availability status :',
        res.data
      );
    } else {
      console.error('An error has occurred:', res.status);
    }
    return res;
  } catch (error) {
    console.error('An error has occurred:', error.status);
    return null;
  }
};

//Get acces token
export const disconnectCall = () => (dispatch) => {
  dispatch({
    type: DISCONNECT_CALL
  });
};

export const fetchTaskOwners = () => async (dispatch) => {
  setBasePath();
  setAuthToken();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  axios
    .get('api/get-organization-user', config)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_TASK_OWNERS,
          payload: res.data.data
        });
      }
    })
    .catch((err) => {
      logOutIfRequestUnauthenticated(err, dispatch);
    });
};

export const saveCallLogs = (body) => {
  setBasePath();
  setAuthToken();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return axios.post('api/save-call-notes', JSON.stringify(body), config);
};

export const convertIncomingCallToConference = async (
  accountSid,
  dialCallSid,
  callSid,
  conferenceFriendlyName
) => {
  setBasePath();
  setAuthToken();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(
      'api/convert-incoming-call-to-conference',
      JSON.stringify({
        accountSid,
        dialCallSid,
        callSid,
        conferenceFriendlyName
      }),
      config
    );
    if (res.status === 200) {
      console.log(
        'Received response for convert incoming call to conference:',
        res.data
      );
    } else {
      console.error('An error has occurred:', res.status);
    }
  } catch (error) {
    console.error('An error has occurred:', error.status);
  }
};

export const endCall = async (accountSid, conferenceFriendlyName) => {
  setBasePath();
  setAuthToken();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(
      'api/end-call',
      JSON.stringify({ accountSid, conferenceFriendlyName }),
      config
    );
    if (res.status === 200) {
      console.log('Received response for end call:', res.data);
    } else {
      console.error('An error has occurred:', res.status);
    }
  } catch (error) {
    console.error('An error has occurred:', error.status);
  }
};

export const syncDurationOfCall = async (conferenceFriendlyName, duration) => {
  setBasePath();
  setAuthToken();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  try {
    const res = await axios.post(
      'api/sync-duration-of-call',
      JSON.stringify({ conferenceFriendlyName, duration }),
      config
    );
    if (res.status === 200) {
      console.log('Received response for sync duration call:', res.data);
    } else {
      console.error('An error has occurred:', res.status);
    }
  } catch (error) {
    console.error('An error has occurred:', error.status);
  }
};
