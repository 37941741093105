import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import {
  auth,
  error,
  contact,
  sms,
  dashboard,
  call,
  userPreference
} from '../reducers';
import thunk from 'redux-thunk';
const initialState = {};

const middleWare = [thunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  return createStore(
    combineReducers({
      auth,
      error,
      contact,
      sms,
      dashboard,
      call,
      userPreference
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middleWare))
  );
}
