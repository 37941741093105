import { IonIcon, IonPage, IonText } from '@ionic/react';
import axios from 'axios';
import { alertCircleOutline } from 'ionicons/icons';
// import uniqBy from 'lodash/uniqBy';
import Pusher from 'pusher-js';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { logOutIfRequestUnauthenticated } from '../actions/authActions';
import {
  fetchChatHistory,
  fetchFromNumbers,
  fetchToNumbers
} from '../actions/smsActions';
import ChatHeader from '../components/Chats/Chat/ChatHeader';
import ChatInput from '../components/Chats/Chat/ChatInput';
import ChatViewer from '../components/Chats/Chat/ChatViewer';
import { environment } from '../environments/environment';

const deliveryMethods = [
  { label: 'SMS', value: 'sms' },
  { label: 'Whatsapp', value: 'whatsapp' }
];

function ChatPage() {
  let history = useHistory();

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [crmLink, setCrmLink] = useState('');
  const [toNumbers, setToNumbers] = useState([]);
  const [fromNumbers, setFromNumbers] = useState([]);
  const [fromNumber, setFromNumber] = useState(null);
  const [toNumber, setToNumber] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(
    deliveryMethods[0].value
  );
  const moment = require('moment-timezone');
  let { id } = useParams();
  //const reduxContact = useSelector(({ sms }) => sms.user); // TODO do we need to get the contact this way too??
  //const error = useSelector(({ error }) => error);
  const dispatch = useDispatch();

  const { data: chat, refetch: refetchChat } = useQuery(
    ['chatHistory', id],
    () => fetchChatHistory(id),
    {
      enabled: !!id
    }
  );

  const { data: fromNumbersData } = useQuery(
    ['fromNumbers', id],
    () => fetchFromNumbers(id),
    {
      enabled: !!id
    }
  );

  const { data: toNumbersData } = useQuery(
    ['toNumbers', id],
    () => fetchToNumbers(id),
    {
      enabled: !!id
    }
  );

  useEffect(() => {
    if (Array.isArray(fromNumbersData?.data?.fromNumbers)) {
      const numbers = fromNumbersData.data.fromNumbers.filter(
        (item) => item.is_non_twilio_number !== 1
      );
      setFromNumbers(numbers);
    }
  }, [fromNumbersData]);

  useEffect(() => {
    if (Array.isArray(toNumbersData?.data?.contactData)) {
      setToNumbers(toNumbersData.data.contactData);
    }
  }, [toNumbersData]);

  // Use the last message to determine TO and FROM number. If there is
  // no last message, use the first contact data found from the user
  useEffect(() => {
    if (fromNumbers && toNumbersData && messages) {
      const lastMessage = messages.length
        ? messages[messages.length - 1]
        : null;
      if (lastMessage) {
        const from = lastMessage.send_from;
        const to = lastMessage.send_to;
        const whatsappMsg =
          from?.includes('whatsapp') || to?.includes('whatsapp');
        if (whatsappMsg) {
          setDeliveryMethod(deliveryMethods[1].value);
        }
        if (lastMessage.direction === 'outbound') {
          if (
            fromNumbers.filter(
              (item) => item.twilio_number === from?.replace('whatsapp:', '')
            ).length > 0
          )
            setFromNumber(from?.replace('whatsapp:', ''));
          else setFromNumber(fromNumbers?.[0]?.twilio_number);

          if (
            toNumbersData.data.contactData.filter(
              (item) => item.to_number === to?.replace('whatsapp:', '')
            ).length > 0
          )
            setToNumber(to?.replace('whatsapp:', ''));
          else setToNumber(toNumbersData.data.contactData?.[0]?.to_number);
        }

        if (lastMessage.direction === 'inbound') {
          if (
            toNumbersData.data.contactData.filter(
              (item) => item.to_number === from?.replace('whatsapp:', '')
            ).length > 0
          )
            setToNumber(from?.replace('whatsapp:', ''));
          else setToNumber(toNumbersData.data.contactData?.[0]?.to_number);

          if (
            fromNumbers.filter(
              (item) => item.twilio_number === to?.replace('whatsapp:', '')
            ).length > 0
          )
            setFromNumber(to?.replace('whatsapp:', ''));
          else setFromNumber(fromNumbers?.[0]?.twilio_number);
        }
      } else {
        setToNumber(toNumbersData.data.contactData?.[0]?.to_number);
        setFromNumber(fromNumbers?.[0]?.twilio_number);
      }
    }
  }, [messages, fromNumbers, toNumbersData]);

  const sortChat = useCallback((chat) => {
    return chat?.sort((date1, date2) => {
      const dateA = new Date(date1.timestamp);
      const dateB = new Date(date2.timestamp);
      return dateA.valueOf() - dateB.valueOf();
    });
  }, []);

  useEffect(() => {
    const pusher = new Pusher(environment.PUSHER_CHANNELS_ID, {
      cluster: 'us2'
    });
    pusher.logToConsole = true;
    const channel = pusher.subscribe('incomming-channel');
    channel.bind('pusher:subscription_succeeded', function (members) {
      console.log('successfully subscribed!');
    });
    channel.bind('chat-event', function (data) {
      console.log('chat-event', data);
      if (id && data.contact_id === parseInt(id)) {
        setMessages((currentMessages) => {
          const isExistingMessage = currentMessages.filter(
            (message) => message.sms_sid === data.smsSid
          );
          if (isExistingMessage.length) {
            return currentMessages;
          }
          return sortChat([
            ...currentMessages,
            {
              sms_sid: data.smsSid,
              user_id: data.user_id,
              contact_id: data.contact_id,
              body: data.message,
              created_at: moment().format('LLLL'),
              direction: 'inbound',
              send_from: data.From,
              send_to: data.To
            }
          ]);
        });
      }
    });
  }, [moment, sortChat, id]);

  // function uniqBy(messages, key, otherKey) {
  //   return messages.filter((item, index, selfArr) => {
  //     return (
  //       selfArr.findIndex((t) => {
  //         if (t[key]) {
  //           return t[key] === item[key];
  //         } else {
  //           return t[otherKey] === item[otherKey];
  //         }
  //       }) === index
  //     );
  //   });
  // }

  useEffect(() => {
    if (chat?.data) {
      const data = chat.data;
      const sortedChat = sortChat(data.chat_n_call);
      setMessages(sortedChat);
      setContact(data.user);
      setCrmLink(data.crm_link);
      setIsLoading(false);
    }
  }, [dispatch, chat, id, sortChat]);

  const onClose = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const payload = {
      zoho_contact_id: contact.zoho_contact_id
    };

    const body = JSON.stringify(payload);

    axios
      .post('/api/mark-conversation-closed', body, config)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        console.log(err.response.data);
        logOutIfRequestUnauthenticated(err, dispatch);
      });
  };
  /*
  useEffect(() => {
    if (error.id === 'SEND_SMS_FAIL') {
      toast.error(error.msg.msg, {
        containerId: 'B',
        transition: Zoom
      });

      dispatch(clearErrors());
    }
    if (error.noError) {
      dispatch(clearErrors());
    }
  }, [dispatch, error]);
  */
  return (
    <IonPage>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100vh',
          backgroundColor: 'white'
        }}
      >
        <ChatHeader
          contact={contact}
          isLoading={isLoading}
          crmLink={crmLink}
          toNumber={toNumber}
          onClose={onClose}
          messages={messages}
          fromNumber={fromNumber}
          setFromNumber={setFromNumber}
          fromNumbers={fromNumbers}
          setToNumber={setToNumber}
          toNumbers={toNumbers}
          setDeliveryMethod={setDeliveryMethod}
          deliveryMethod={deliveryMethod}
          deliveryMethods={deliveryMethods}
        />
        <ChatViewer messages={messages} isLoading={isLoading} />
        {fromNumbers.length === 0 && !isLoading ? (
          <div
            style={{
              backgroundColor: '#ffcc0066',
              padding: 10,
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <IonIcon icon={alertCircleOutline} style={{ marginRight: 10 }} />
            <IonText style={{ fontSize: 14, flex: 1 }}>
              You can't send messages as you don't have access to any Twilio
              from numbers. Please ask your administrator to grant access to a
              from number in the CRM.
            </IonText>
          </div>
        ) : (
          <ChatInput
            isLoading={isLoading}
            setMessages={setMessages}
            contact={contact}
            messages={messages}
            toNumber={toNumber}
            fromNumber={fromNumber}
            setFromNumber={setFromNumber}
            fromNumbers={fromNumbers}
            setToNumber={setToNumber}
            toNumbers={toNumbers}
            setDeliveryMethod={setDeliveryMethod}
            deliveryMethod={deliveryMethod}
            deliveryMethods={deliveryMethods}
            refetchChat={refetchChat}
          />
        )}
      </div>
    </IonPage>
  );
}

export default ChatPage;
