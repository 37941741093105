import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Capacitor, Plugins } from '@capacitor/core';
import { CordovaPluginTwilioVoiceSdk } from '@ionic-native/cordova-plugin-twiliovoicesdk';
import {
  convertIncomingCallToConference,
  endCall,
  fetchAccessToken,
  syncDurationOfCall,
  updateAvailabilityStatus
} from '../actions/callActions';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import {
  REMOVE_USER_PREFERENCE,
  SET_USER_PREFERENCE,
  UPDATE_CALL_STATUS
} from '../actions/types';
import { Insomnia } from '@awesome-cordova-plugins/insomnia';
import { IonLoading } from '@ionic/react';
import './TwilioWrapper.css';
import isTrue from '../utils/isTrue';
import * as Sentry from '@sentry/react';

const { LocalNotifications } = Plugins;

let Twilio = window.Twilio;
let cordovaTwilioSDkObject = CordovaPluginTwilioVoiceSdk.create();
cordovaTwilioSDkObject.load();
let callStartedDate;
let conferenceFriendlyName;
let callerConferenceFriendlyName;
let contactName;
let dialCallSid;
let callSid;
let callPageOpened = false;

const TwilioWrapper = ({ children }) => {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const makeCallParam = useSelector(({ call }) => call.makeCall);
  const endCallParam = useSelector(({ call }) => call.endCall);
  const unmuteCallParam = useSelector(({ call }) => call.unmuteCall);
  const muteCallParam = useSelector(({ call }) => call.muteCall);
  const setCallSpeakerParam = useSelector(({ call }) => call.setCallSpeaker);
  const sendCallDigitsParam = useSelector(({ call }) => call.sendCallDigits);

  const enabledTelephony = useSelector(({ userPreference }) =>
    isTrue(userPreference?.settings?.features?.calling_functionality)
  );
  const twilioAccessTokenRefreshDate = useSelector(
    ({ userPreference }) => userPreference?.twilioAccessTokenRefreshDate
  );
  const twilioAccessToken = useSelector(
    ({ userPreference }) => userPreference?.access_token
  );
  // const accountSid = useSelector(
  //   ({ userPreference }) => userPreference?.settings?.accountSid
  // );
  // const accountSidToSMS = useSelector(
  //   ({ userPreference }) => userPreference?.settings?.accountSidToSMS
  // );
  const accountSidToCall = useSelector(
    ({ userPreference }) => userPreference?.settings?.accountSidToCall
  );
  const organisationId = useSelector(
    ({ userPreference }) => userPreference?.settings?.organisationID
  );
  const zohoUserId = useSelector(
    ({ userPreference }) => userPreference?.zoho_user_id
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const FIFTY_MINUTES_MS = 50 * 60 * 1000;
  const [completeInit, setCompleteInit] = useState(false);
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);

  const initTwilio = async () => {
    try {
      // permission stuff
      const androidPermissions = new AndroidPermissions();
      const result = await androidPermissions.checkPermission(
        androidPermissions.PERMISSION.RECORD_AUDIO
      );
      if (!result.hasPermission) {
        await androidPermissions.requestPermissions([
          androidPermissions.PERMISSION.RECORD_AUDIO
        ]);
      }

      // @ts-ignore
      if (!Twilio) {
        console.log('No twilio');
        throw new Error('Twilio plugin load failure');
      }

      // fetch access token
      const { telephonyEnabled, token } = await updateAccessToken();
      initAccessTokenRefreshInterval();
      if (!telephonyEnabled) {
        setCompleteInit(true);
        return;
      }

      Twilio.TwilioVoiceClient.initialize(token);

      // Handle Errors
      Twilio.TwilioVoiceClient.error(function (error) {
        // Report error to Sentry
        let errorMessage = 'Unknown error';
        if (error?.message) {
          errorMessage = error.message;
        }
        Sentry.captureException(new Error(errorMessage));
        alert(errorMessage);

        Insomnia.allowSleepAgain();

        if (accountSidToCall) {
          const newConferenceFriendlyName = conferenceFriendlyName
            ? conferenceFriendlyName
            : callerConferenceFriendlyName;
          if (newConferenceFriendlyName) {
            endCall(accountSidToCall, newConferenceFriendlyName);
          }
        }

        if (callPageOpened) {
          history.goBack();
          callPageOpened = false;
        }

        callStartedDate = null;
        contactName = null;
        conferenceFriendlyName = null;
        callerConferenceFriendlyName = null;
        dialCallSid = null;
        callSid = null;
      });

      // Accept or reject a call - only needed on Android - iOS uses CallKit
      Twilio.TwilioVoiceClient.callinvitereceived(function (call) {
        console.log('incoming call');
        conferenceFriendlyName = call.conferenceFriendlyName;
        contactName = call.contactName;
        dialCallSid = call.dialCallSid;
        callSid = call.callSid;
      });

      Twilio.TwilioVoiceClient.calldidconnect(function (call) {
        console.log('calldidconnect');

        Insomnia.keepAwake();

        updateAvailabilityStatus('ON_CALL');

        dispatch({
          type: UPDATE_CALL_STATUS,
          payload: 'Connected'
        });

        callStartedDate = new Date();

        if (contactName && conferenceFriendlyName && call && call.from) {
          history.push(`/call/${call.from}/${contactName}`);
          callPageOpened = true;

          if (accountSidToCall && dialCallSid && callSid) {
            convertIncomingCallToConference(
              accountSidToCall,
              dialCallSid,
              callSid,
              conferenceFriendlyName
            );
          }
        }
      });

      Twilio.TwilioVoiceClient.calldiddisconnect(function (call) {
        Insomnia.allowSleepAgain();

        callDisconnected(call);
      });
    } catch (e) {
      alert(e);
    } finally {
      setCompleteInit(true);
    }
  };

  const updateAccessToken = async () => {
    const response = await fetchAccessToken();
    if (response.status === 200) {
      let token = '';
      if (response.data.access_token) {
        token = response.data.access_token;
      }
      dispatch({
        type: SET_USER_PREFERENCE,
        payload: response.data
      });

      return {
        telephonyEnabled: isTrue(
          response.data.settings.features.calling_functionality
        ),
        token
      };
    }

    return {
      telephonyEnabled: false
    };
  };

  const initAccessTokenRefreshInterval = () => {
    const interval = setInterval(() => {
      updateAccessToken();
    }, FIFTY_MINUTES_MS);

    return () => clearInterval(interval);
  };

  const sendLocalNotification = (callDuration, fromNumber) => {
    LocalNotifications.schedule({
      notifications: [
        {
          id: 1,
          title: 'Smooth Messenger',
          body: `Log details for call with ${fromNumber}`,
          extra: {
            callDuration: callDuration,
            conferenceFriendlyName: conferenceFriendlyName,
            contactName: contactName
          }
        }
      ]
    });
  };

  const callDisconnected = (call) => {
    console.log('calldiddisconnect');

    const newConferenceFriendlyName = conferenceFriendlyName
      ? conferenceFriendlyName
      : callerConferenceFriendlyName;

    if (accountSidToCall && newConferenceFriendlyName) {
      endCall(accountSidToCall, newConferenceFriendlyName);
    }

    if (!callPageOpened) {
      callStartedDate = null;
      contactName = null;
      conferenceFriendlyName = null;
      callerConferenceFriendlyName = null;
      dialCallSid = null;
      callSid = null;
      return;
    }

    updateAvailabilityStatus('AVAILABLE');

    let callDuration = 0;
    if (callStartedDate) {
      const date = new Date();
      callDuration = (date.getTime() - callStartedDate.getTime()) / 1000;
    }

    if (contactName && callDuration) {
      if (conferenceFriendlyName) {
        // skip for outgoing
        sendLocalNotification(callDuration, call.from);
      }

      if (newConferenceFriendlyName) {
        history.replace(
          `/calllog/${callDuration}/${newConferenceFriendlyName}/${contactName}`
        );

        syncDurationOfCall(newConferenceFriendlyName, parseInt(callDuration));
      }
      callPageOpened = false;
    } else {
      history.goBack();
    }

    callStartedDate = null;
    contactName = null;
    conferenceFriendlyName = null;
    callerConferenceFriendlyName = null;
    dialCallSid = null;
    callSid = null;
  };

  const makeCall = async () => {
    if (!enabledTelephony) return;
    if (!twilioAccessTokenRefreshDate) return;

    let newTwilioAccessToken = twilioAccessToken;

    // check token expired
    const timeElapsedInMin =
      (new Date().getTime() - twilioAccessTokenRefreshDate.getTime()) /
      (1000 * 60);
    const isTwilioAccessTokenExpired = timeElapsedInMin > 50;
    if (isTwilioAccessTokenExpired) {
      setIsRefreshingToken(true);
      const { telephonyEnabled, token } = await updateAccessToken();
      if (telephonyEnabled && token) {
        newTwilioAccessToken = token;
      } else {
        newTwilioAccessToken = null;
      }
      setIsRefreshingToken(false);
    }

    if (!newTwilioAccessToken) return;

    const fromNumber = makeCallParam.fromNumber;
    const toNumber = makeCallParam.toNumber;
    const _contactName = makeCallParam.contactName;
    if (
      !fromNumber ||
      !toNumber ||
      !_contactName ||
      !organisationId ||
      !zohoUserId
    ) {
      return;
    }

    // open call page
    dispatch({
      type: UPDATE_CALL_STATUS,
      payload: 'Calling...'
    });
    history.push(`/call/${toNumber}/${_contactName}`);
    callPageOpened = true;

    // make a call
    contactName = _contactName;
    callerConferenceFriendlyName = uuidv5(
      `${toNumber}_${fromNumber}`,
      uuidv4()
    );
    let callTo = {
      phoneNumber: toNumber,
      callerId: fromNumber,
      conferenceFriendlyName: callerConferenceFriendlyName,
      organisationId: organisationId,
      zohoUserId: zohoUserId
    };
    Twilio.TwilioVoiceClient.call(newTwilioAccessToken, callTo);
  };

  useEffect(() => {
    if (Capacitor.isNative && isAuthenticated) {
      dispatch({
        type: REMOVE_USER_PREFERENCE
      });

      initTwilio();
    } else {
      // for web platform
      setCompleteInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!makeCallParam || makeCallParam.isInitial) {
      return;
    }

    makeCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeCallParam]);

  useEffect(() => {
    if (!endCallParam || endCallParam.isInitial) {
      return;
    }

    Twilio.TwilioVoiceClient.disconnect();
  }, [endCallParam]);

  useEffect(() => {
    if (!unmuteCallParam || unmuteCallParam.isInitial) {
      return;
    }

    Twilio.TwilioVoiceClient.unmuteCall();
  }, [unmuteCallParam]);

  useEffect(() => {
    if (!muteCallParam || muteCallParam.isInitial) {
      return;
    }

    Twilio.TwilioVoiceClient.muteCall();
  }, [muteCallParam]);

  useEffect(() => {
    if (!setCallSpeakerParam || setCallSpeakerParam.isInitial) {
      return;
    }

    const isSpeakerOn = setCallSpeakerParam.isSpeakerOn;
    Twilio.TwilioVoiceClient.setSpeaker(isSpeakerOn ? 'on' : 'off');
  }, [setCallSpeakerParam]);

  useEffect(() => {
    if (!sendCallDigitsParam || sendCallDigitsParam.isInitial) {
      return;
    }

    const digit = sendCallDigitsParam.digit;
    if (digit) {
      Twilio.TwilioVoiceClient.sendDigits(digit);
    }
  }, [sendCallDigitsParam]);

  if (!completeInit) {
    return (
      <IonLoading
        isOpen={true}
        showBackdrop={false}
        cssClass="loadingbar"
        message={'Please wait...'}
      />
    );
  } else {
    return (
      <>
        {children}
        <IonLoading
          isOpen={isRefreshingToken}
          onDidDismiss={() => setIsRefreshingToken(false)}
          message={
            'One moment while we re-establish a connection to Twilio. Refreshing...'
          }
        />
      </>
    );
  }
};
export default TwilioWrapper;
