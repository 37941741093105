import { MEDIA_STATUS } from '@awesome-cordova-plugins/media';
import { IonButton, IonIcon, IonRange, IonSpinner } from '@ionic/react';
import { checkmarkDoneOutline, pause, play } from 'ionicons/icons';
import moment from 'moment';
import { useCallback } from 'react';
import Linkify from 'react-linkify';
import './VoiceMessage.css';

const VoiceMessage = ({
  contents: {
    timestamp,
    direction,
    isSending,
    voice_mail_recording_url: voiceMailRecordingUrl,
    call_recording_url: callRecordingUrl,
    duration: msgDuration,
    call_note: transcript
  },
  playAudioButtonClick,
  networkDuration,
  mediaStatus,
  currentPosition
}) => {
  const audioUrl = voiceMailRecordingUrl
    ? voiceMailRecordingUrl
    : callRecordingUrl;
  const duration = networkDuration >= 0 ? networkDuration : msgDuration;

  const zeroPad = (nr, base) => {
    var len = String(base).length - String(nr).length + 1;
    return len > 0 ? new Array(len).join('0') + nr : nr;
  };

  const durationText = (durationInSeconds) => {
    const intDurationInSeconds = parseInt(durationInSeconds);
    const hours = parseInt(intDurationInSeconds / 3600);
    const minutes = parseInt(intDurationInSeconds / 60) % 60;
    const seconds = intDurationInSeconds % 60;

    if (hours > 0) {
      return `${hours}:${zeroPad(minutes, 10)}:${zeroPad(seconds, 10)}`;
    } else {
      return `${zeroPad(minutes, 10)}:${zeroPad(seconds, 10)}`;
    }
  };

  const getPlayIcon = useCallback(() => {
    if (mediaStatus === MEDIA_STATUS.RUNNING) {
      return pause;
    } else {
      return play;
    }
  }, [mediaStatus]);

  return (
    <div
      className={`voicemessage ${
        direction === 'outbound' && 'voicemessage__sender'
      }`}
    >
      <section
        style={{
          marginTop: '10px',
          marginBottom: 0,
          backgroundColor: '#f3f3f5'
        }}
      >
        <div className="voicemessage__audio">
          <IonButton
            className="no-padding"
            size="small"
            fill="clear"
            onClick={() => playAudioButtonClick(audioUrl)}
          >
            <IonIcon size="small" icon={getPlayIcon()} />
          </IonButton>
          <IonRange
            className="ion-range"
            style={{ width: '150px', height: '28px' }}
            min={0}
            max={duration}
            value={currentPosition}
          ></IonRange>
          <small className="voicemessage__timelabel">
            {durationText(currentPosition)} : {durationText(duration)}
          </small>
        </div>
        {direction === 'outbound' && (
          <div style={{ paddingLeft: '10px' }}>
            {isSending ? (
              <IonSpinner color="primary" name="lines-small" />
            ) : (
              <IonIcon color="primary" icon={checkmarkDoneOutline} />
            )}
          </div>
        )}
      </section>
      {transcript && transcript.length > 0 ? (
        <section
          style={{
            marginTop: 0,
            marginBottom: '10px',
            backgroundColor: '#0754dc',
            color: 'white'
          }}
        >
          <div>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {transcript}
            </Linkify>
          </div>
        </section>
      ) : (
        <view style={{ marginTop: 0, marginBottom: '10px' }}></view>
      )}
      <small
        className={`voicemessage__date ${
          direction === 'outbound' && 'voicemessage__date__outbound'
        }`}
      >
        {moment(timestamp).isSame(new Date(), 'date')
          ? moment(timestamp).format('LT')
          : moment(timestamp).format('LLL')}
      </small>
    </div>
  );
};

export default VoiceMessage;
