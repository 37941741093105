import {
  FETCH_TASK_OWNERS,
  UPDATE_CALL_STATUS,
  MAKE_CALL,
  END_CALL,
  MUTE_CALL,
  UNMUTE_CALL,
  SET_CALL_SPEAKER,
  SEND_CALL_DIGITS
} from '../actions/types';

const initialState = {
  taskOwners: [],
  callStatus: '',
  makeCall: {
    isInitial: true,
    isManualTrigger: false
  },
  endCall: {
    isInitial: true,
    isManualTrigger: false
  },
  muteCall: {
    isInitial: true,
    isManualTrigger: false
  },
  unmuteCall: {
    isInitial: true,
    isManualTrigger: false
  },
  setCallSpeaker: {
    isInitial: true,
    isManualTrigger: false
  },
  sendCallDigits: {
    isInitial: true,
    isManualTrigger: false
  }
};

export default function call(state = initialState, action) {
  switch (action.type) {
    case FETCH_TASK_OWNERS:
      return {
        ...state,
        taskOwners: action.payload
      };
    case UPDATE_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload
      };
    case MAKE_CALL:
      return {
        ...state,
        makeCall: {
          isInitial: false,
          isManualTrigger: !state.makeCall.isManualTrigger,
          ...action.payload
        }
      };
    case END_CALL:
      return {
        ...state,
        endCall: {
          isInitial: false,
          isManualTrigger: !state.endCall.isManualTrigger
        }
      };
    case MUTE_CALL:
      return {
        ...state,
        muteCall: {
          isInitial: false,
          isManualTrigger: !state.muteCall.isManualTrigger
        }
      };
    case UNMUTE_CALL:
      return {
        ...state,
        unmuteCall: {
          isInitial: false,
          isManualTrigger: !state.unmuteCall.isManualTrigger
        }
      };
    case SET_CALL_SPEAKER:
      return {
        ...state,
        setCallSpeaker: {
          isInitial: false,
          isManualTrigger: !state.setCallSpeaker.isManualTrigger,
          ...action.payload
        }
      };
    case SEND_CALL_DIGITS:
      return {
        ...state,
        sendCallDigits: {
          isInitial: false,
          isManualTrigger: !state.sendCallDigits.isManualTrigger,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
