import * as Sentry from '@sentry/react';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

export async function isStorageAvailable() {
  try {
    const x = '__storage_test__';
    await Storage.set({ key: x, value: x });
    await Storage.remove({ key: x });
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      // acknowledge QuotaExceededError only if there's something already stored
      // storage &&
      // storage.length !== 0
    );
  }
}

export async function safelyRetrieveLocalStorageItem(storageKey: string) {
  try {
    return Storage.get({ key: storageKey });
  } catch (err) {
    const e = err as any;
    const errorWithMoreInfo = new Error(e);
    errorWithMoreInfo.message = `${e.message}${storageKey}`;
    Sentry.captureException(errorWithMoreInfo);
  }
}

export async function safelySetLocalStorageItem(
  storageKey: string,
  value: string
) {
  try {
    await Storage.set({ key: storageKey, value: value });
  } catch (err) {
    const e = err as any;
    const errorWithMoreInfo = new Error(e);
    errorWithMoreInfo.message = `${e.message}${storageKey}`;
    Sentry.captureException(errorWithMoreInfo);
  }
}

export function safelyParseJSON(
  jsonString: string,
  useCase: string,
  fallbackResponseIfError: any,
  logError = false
) {
  try {
    return JSON.parse(jsonString);
  } catch (err) {
    const e = err as any;
    const errorWithMoreInfo = new Error(e);
    errorWithMoreInfo.message = `${e.message}${useCase} ${JSON.stringify(
      jsonString
    )}`;
    if (logError) {
      Sentry.captureException(errorWithMoreInfo);
    }

    return fallbackResponseIfError;
  }
}
