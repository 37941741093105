import { REMOVE_USER_PREFERENCE, SET_USER_PREFERENCE } from '../actions/types';

const initialState = JSON.parse(localStorage.getItem('user_preference'));
export default function userPreference(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PREFERENCE: {
      localStorage.setItem('user_preference', JSON.stringify(action.payload));
      return {
        ...state,
        twilioAccessTokenRefreshDate: new Date(),
        ...action.payload
      };
    }
    case REMOVE_USER_PREFERENCE: {
      localStorage.removeItem('user_preference');
      return {};
    }
    default:
      return state;
  }
}
