import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonText
} from '@ionic/react';
import {
  backspace,
  callOutline,
  keypadOutline,
  micOffOutline,
  micOutline,
  volumeHighOutline,
  volumeMuteOutline
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  END_CALL,
  MUTE_CALL,
  SEND_CALL_DIGITS,
  SET_CALL_SPEAKER,
  UNMUTE_CALL
} from '../actions/types';
import useLongPress from '../components/useLongPress';

const CallPage = () => {
  const dispatch = useDispatch();

  const [input, setInput] = useState('');

  const { toNumber, contactName } = useParams();
  const [isSpeakerOn, setSpeaker] = useState(false);
  const [isMute, setMute] = useState(false);
  const [isDialpadOpen, setDialpadOpen] = useState(false);
  const callStatus = useSelector(({ call }) => call.callStatus);

  const endCall = () => {
    dispatch({
      type: END_CALL
    });
  };

  const toggleMute = () => {
    setMute(!isMute);
  };

  const toggleSpeaker = () => {
    setSpeaker(!isSpeakerOn);
  };

  const toggleDialpad = () => {
    setDialpadOpen(!isDialpadOpen);
  };

  function numButton(val, text) {
    return (
      <IonCol size="3">
        <IonButton
          style={{ height: 45 }}
          expand="block"
          onClick={() => append(val)}
        >
          <IonCol>
            <IonText style={{ fontSize: 20 }}>{val}</IonText>
            <br />
            <IonText style={{ fontSize: 8 }}>{text}</IonText>
          </IonCol>
        </IonButton>
      </IonCol>
    );
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500
  };
  const onLongPress = () => {
    setInput('');
  };

  const onClick = () => {
    setInput(input.slice(0, input.length - 1));
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  function append(n) {
    setInput(input + n);
    dispatch({
      type: SEND_CALL_DIGITS,
      payload: {
        digit: n
      }
    });
  }

  useEffect(() => {
    dispatch({
      type: SET_CALL_SPEAKER,
      payload: {
        isSpeakerOn
      }
    });
  }, [dispatch, isSpeakerOn]);

  useEffect(() => {
    if (isMute) {
      dispatch({
        type: MUTE_CALL
      });
    } else {
      dispatch({
        type: UNMUTE_CALL
      });
    }
  }, [dispatch, isMute]);

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonCol size="1">
          <div
            style={{ fontSize: 20, fontWeight: 'bold' }}
            className="ion-text-center"
          >
            {contactName}
          </div>
          <div
            style={{ fontSize: 20, fontWeight: 'bold' }}
            className="ion-text-center"
          >
            {toNumber}
          </div>
          <div style={{ marginTop: 10 }} className="ion-text-center">
            {callStatus}
          </div>
        </IonCol>

        {isDialpadOpen && (
          <IonCol size="1">
            <IonItem>
              <IonCol size="3" />
              <IonInput
                value={input}
                readonly={true}
                style={{ textAlign: 'center', fontSize: 20 }}
                maxlength={20}
                type="tel"
              />
              <IonCol size="3" style={{ width: 20 }}>
                <IonButton expand="block" fill="clear" {...longPressEvent}>
                  <IonIcon size="large" icon={backspace} />
                </IonButton>
              </IonCol>
            </IonItem>
            <IonRow className="ion-justify-content-evenly">
              {numButton('1', '')}
              {numButton('2', 'ABC')}
              {numButton('3', 'DEF')}
            </IonRow>
            <IonRow className="ion-justify-content-evenly">
              {numButton('4', 'GHI')}
              {numButton('5', 'JKL')}
              {numButton('6', 'MNO')}
            </IonRow>
            <IonRow className="ion-justify-content-evenly">
              {numButton('7', 'PQRS')}
              {numButton('8', 'TUV')}
              {numButton('9', 'WXYZ')}
            </IonRow>
            <IonRow className="ion-justify-content-evenly">
              <IonCol size="3">
                <IonButton
                  style={{ fontSize: 20, height: 45 }}
                  expand="block"
                  fill="clear"
                  onClick={() => append('*')}
                >
                  &#8727;
                </IonButton>
              </IonCol>
              {numButton('0', '')}
              <IonCol size="3">
                <IonButton
                  style={{ fontSize: 20, height: 45 }}
                  expand="block"
                  fill="clear"
                  onClick={() => append('#')}
                >
                  #
                </IonButton>
              </IonCol>
            </IonRow>
          </IonCol>
        )}
      </IonContent>

      <div className="ion-text-center">
        <IonButton
          fill="clear"
          style={{ height: 45 }}
          onClick={toggleMute}
          class="ion-margin ion-text-center"
        >
          <IonIcon size="large" icon={isMute ? micOffOutline : micOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          style={{ height: 45 }}
          onClick={toggleDialpad}
          class="ion-margin ion-text-center"
        >
          <IonIcon size="large" icon={keypadOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          style={{ height: 45 }}
          onClick={toggleSpeaker}
          class="ion-margin ion-text-center"
        >
          <IonIcon
            size="large"
            icon={isSpeakerOn ? volumeHighOutline : volumeMuteOutline}
          />
        </IonButton>
        <IonButton
          fill="solid"
          shape="round"
          style={{ height: 80 }}
          color={'danger'}
          onClick={endCall}
          class="ion-margin ion-text-center"
        >
          <IonIcon size="large" icon={callOutline} />
        </IonButton>
      </div>
    </IonPage>
  );
};

export default CallPage;
