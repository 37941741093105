import { Capacitor } from '@capacitor/core';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPopover,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import {
  callOutline,
  checkmark,
  ellipsisVerticalOutline
} from 'ionicons/icons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAKE_CALL } from '../../../actions/types';
import isTrue from '../../../utils/isTrue';
import CallSettingsPopover from './CallSettingsPopover';
import ChatSettingsPopover from './ChatSettingsPopover';

const ChatHeader = (props) => {
  const {
    contact,
    crmLink,
    isLoading,
    toNumber,
    setToNumber,
    toNumbers,
    fromNumber,
    setFromNumber,
    fromNumbers,
    deliveryMethod,
    setDeliveryMethod,
    deliveryMethods,
    onClose
  } = props;
  const dispatch = useDispatch();

  const enabledTelephony = useSelector(({ userPreference }) =>
    isTrue(userPreference?.settings?.features?.calling_functionality)
  );

  const outgoingNumber = useSelector(({ userPreference }) =>
    userPreference?.twilio_module_numbers?.find(
      (item) => item.user_configured_for_outgoing === 1
    )
  );

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  });

  const [openZohoPopover, setOpenZohoPopover] = useState({
    showPopover: false,
    event: undefined
  });

  const [callPopoverState, setShowCallPopover] = useState({
    showPopover: false,
    event: undefined
  });

  const handleCrmLink = (e) => {
    setOpenZohoPopover({ showPopover: true, event: e });
  };

  const makeCall = (fromNumber, toNumber) => {
    const contactName = hasName() ? fullName() : toNumber;

    dispatch({
      type: MAKE_CALL,
      payload: {
        fromNumber,
        toNumber,
        contactName
      }
    });
  };

  const hasName = () => {
    return contact.first_name || contact.last_name;
  };

  const fullName = () => {
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  };

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text="" defaultHref="/Dashboard/chat"></IonBackButton>
        </IonButtons>
        <IonTitle
          mode="md"
          style={{
            padding: 0,
            paddingLeft: Capacitor.getPlatform() === 'android' ? 0 : 10
          }}
        >
          {isLoading ? (
            <IonSkeletonText
              animated
              style={{
                height: '30px',
                width: '30%',
                borderRadius: '30px'
              }}
            />
          ) : (
            <>
              <a
                href="!#"
                onClick={handleCrmLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                {fullName()}
              </a>
              <div style={{ fontSize: '11px' }}>{toNumber}</div>
            </>
          )}
        </IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={onClose} style={{ margin: 0 }}>
            <IonIcon style={{ height: 30, width: 30 }} icon={checkmark} />
            <IonLabel style={{ fontSize: 8 }}>
              No Reply
              <br />
              Needed
            </IonLabel>
          </IonButton>

          {Capacitor.isNative &&
            !isLoading &&
            toNumber &&
            outgoingNumber &&
            enabledTelephony && (
              <IonButton
                fill="clear"
                size="small"
                onClick={(e) => {
                  e.persist();
                  setShowCallPopover({ showPopover: true, event: e });
                }}
              >
                <IonIcon size="large" icon={callOutline} />
              </IonButton>
            )}

          <IonButton
            fill="clear"
            size="small"
            onClick={(e) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          >
            <IonIcon size="large" icon={ellipsisVerticalOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <ChatSettingsPopover
        toNumber={toNumber}
        setToNumber={setToNumber}
        toNumbers={toNumbers}
        fromNumber={fromNumber}
        setFromNumber={setFromNumber}
        fromNumbers={fromNumbers}
        deliveryMethods={deliveryMethods}
        deliveryMethod={deliveryMethod}
        setDeliveryMethod={setDeliveryMethod}
        popoverState={popoverState}
        setShowPopover={setShowPopover}
      />
      <CallSettingsPopover
        toNumbers={toNumbers}
        popoverState={callPopoverState}
        setShowPopover={setShowCallPopover}
        makeCall={makeCall}
      />
      <IonPopover
        event={openZohoPopover.event}
        isOpen={openZohoPopover.showPopover}
        onDidDismiss={() =>
          setOpenZohoPopover({ showPopover: false, event: null })
        }
      >
        <IonButton
          onClick={async () => {
            setOpenZohoPopover({ showPopover: false, event: null });
            window.open(crmLink, '_system');
          }}
          style={{ margin: 20 }}
        >
          Open In Zoho Crm
        </IonButton>
      </IonPopover>
    </IonHeader>
  );
};

export default ChatHeader;
