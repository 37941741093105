export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const REMOVE_AUTH_ERROR = 'REMOVE_AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const NO_ERRORS = 'NO_ERRORS';
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const STORE_SETTINGS_SUCCESS = 'STORE_SETTINGS_SUCCESS';
export const SAVE_CONTACT_SUCCESS = 'SAVE_CONTACT_SUCCESS';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const CLEAR_CONTACTS_DATA = 'CLEAR_CONTACTS_DATA';
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const CLEAR_CHAT_HISTORY = 'CLEAR_CHAT_HISTORY';
export const SYNC_CONTACTS_SUCCESS = 'SYNC_CONTACTS_SUCCESS';
export const DISCONNECT_CALL = 'DISCONNECT_CALL';
export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const LOGIN_TEMP = 'LOGIN_TEMP';
export const NEVER_ASK_FOR_PERMISSION = 'NEVER_ASK_FOR_PERMISSION';
export const ON_UPDATE_HOOKS = 'ON_UPDATE_HOOKS';
export const FETCH_TO_NUMBERS = 'FETCH_TO_NUMBERS';
export const FETCH_TO_NUMBERS_SUCCESS = 'FETCH_TO_NUMBERS_SUCCESS';
export const FETCH_FROM_NUMBERS = 'FETCH_FROM_NUMBERS';
export const FETCH_FROM_NUMBERS_SUCCESS = 'FETCH_FROM_NUMBERS_SUCCESS';
export const FETCH_TASK_OWNERS = 'FETCH_TASK_OWNERS';
export const UPDATE_CALL_STATUS = 'UPDATE_CALL_STATUS';
export const MAKE_CALL = 'MAKE_CALL';
export const END_CALL = 'END_CALL';
export const MUTE_CALL = 'MUTE_CALL';
export const UNMUTE_CALL = 'UNMUTE_CALL';
export const SET_CALL_SPEAKER = 'SET_CALL_SPEAKER';
export const SEND_CALL_DIGITS = 'SEND_CALL_DIGITS';

export const SET_USER_PREFERENCE = 'SET_USER_PREFERENCE';
export const REMOVE_USER_PREFERENCE = 'REMOVE_USER_PREFERENCE';
